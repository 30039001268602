import _ from 'lodash'
import { colors, createMuiTheme, responsiveFontSizes } from '@material-ui/core'
import typography from './typography'

const baseOptions = {
  typography,
  overrides: {
    MuiFilledInput: {
      root: {
        backgroundColor: '#18462e',
        border: '1px solid #ececec',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        color: 'white',
        '&:hover': {
          backgroundColor: '#18462e',
        },
        '&$focused': {
          backgroundColor: '#18462e',
        },
      },
      underline: {
        '&:before': {
          // borderBottomColor: "red"
        },
        '&:hover:not(.Mui-focused):before': {
          // borderBottomColor: "green"
        },
        '&:after': {
          // focused
          // borderBottomColor: "purple"
        },
      },
    },
    MuiInputLabel: {
      filled: {
        color: '#FFF',
        '&$focused': {
          // color: "#16fff7",
        },
        '.MuiFormControl-root:hover &:not($focused)': {
          // color: "#16fff7",
        },
      },
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden',
        fontWeight: 400,
        fontSize: '16px',
        padding: '2px 25px',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)',
      },
    },
    MuiButton: {
      containedPrimary: {
        marginTop: 15,

        boxShadow: 'none',
        borderRadius: 5,
        color: '#fff',
        backgroundColor: '#226f6c',
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: '#fafafa',
          color: '#226f6c',
          borderColor: '#226f6c',
          border: '1px solid #226f6c',
        },
      },
      containedSecondary: {
        marginTop: 15,
        backgroundColor: '',
        boxShadow: 'none',
        borderRadius: 5,
        color: '#fff',
        '&:hover': {
          boxShadow: 'none',
          border: '1px solid #3b0668',
          color: '#fff',
          // backgroundColor: "#3b0668",
        },
        // outlinedPrimary: {
        //   "&:hover": {
        //     boxShadow: "none",
        //     backgroundClor: "#091D3A",
        //   },
        // },
      },
      containedSizeLarge: {
        color: '#fff',
        padding: '14px 50px',
      },
      outlined: {
        boxShadow: 'none',
        borderRadius: 5,
        borderWidth: 2,
      },
      outlinedPrimary: {
        borderRadius: 5,
        color: '#fff',
        borderColor: '#091d3a',
        backgroundColor: '#091d3a',
        boxShadow: 'none',

        '&:hover': {
          color: '#091d3a',
          borderColor: '#091d3a',
          backgroundColor: 'white',
        },
      },
      outlinedSizeLarge: {},
    },
    MuiPaper: {
      root: {
        // color: "#ffffff",
      },
      outlined: {
        backgroundColor: '#121212',
        padding: '10px',
        borderRadius: '10px',
      },
    },
    MuiLink: {
      underlineHover: {
        '&:hover': {
          textDecoration: 'none',
        },
      },
    },
  },
}

const themesOptions = {
  typography: {
    fontWeight: 400,
    fontFamily: "'Poppins', sans-serif;",
  },
  palette: {
    type: 'light',
    action: {
      primary: '#20509e',
    },
    background: {
      default: '#FBFBFD',
      dark: '#f3f7f9',
      paper: colors.common.white,
    },
    primary: {
      main: '#16fff7',
      dark: '#16fff7',
      light: '#16fff7',
    },
    secondary: {
      main: '#3b0668',
    },
    warning: {
      main: '#ffae33',
      dark: '#ffae33',
      light: '#fff1dc',
    },
    success: {
      main: '#54e18c',
      dark: '#54e18c',
      light: '#e2faec',
    },
    error: {
      main: '#ff7d68',
      dark: '#ff7d68',
      light: '#ffe9e6',
    },
    text: {
      primary: '#52565c',
      secondary: '#999999',
    },
    common: {
      black: '#222222',
    },
  },
}

export const createTheme = (config = {}) => {
  let theme = createMuiTheme(_.merge({}, baseOptions, themesOptions))

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme)
  }

  return theme
}
