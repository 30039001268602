import React, { createContext, useEffect } from 'react';
import { injected } from 'src/connectors';
import { useWeb3React } from '@web3-react/core';
import { getContract } from 'src/utils';
import { NFTRegistry, token, Finalcontractaddress } from 'src/constants';
import NFTRegistryABI from 'src/abis/NFTRegistryABI.json';
import TokenABI from 'src/abis/Abi2.json';
import ThetaWalletConnect from '@thetalabs/theta-wallet-connect';
import * as thetajs from '@thetalabs/theta-js';
import abi from 'src/abis/Abi2.json';
import ThetaPunkABI from 'src/abis/ThetaPunkABI.json';
import axios from 'axios';
import Web3 from 'web3';

export const UserContext = createContext();

const setSession = (userAddress) => {
  if (userAddress) {
    sessionStorage.setItem('userAddress', userAddress);
  } else {
    sessionStorage.removeItem('userAddress');
  }
};

export default function AuthProvider(props) {
  const { activate, active, account, library, chainId } = useWeb3React();
  const [nftList, setNftList] = React.useState([]);
  const [userTotalNFTS, setUserTotalNFTS] = React.useState(0);
  const [totalSupply, setTotalSupply] = React.useState(0);
  const [maxSupply, setMaxSupply] = React.useState(0);
  let data = {
    nftList,
    userTotalNFTS,
    maxSupply,
    totalSupply,
    getGalleryData: () => Gallery(),
    getMoreData: () => getMoreData(),
    updateUser: (account) => {
      setSession(account);
    },
    connectWallet: async () => {
      try {
        activate(injected, undefined, true).catch((error) => {
          if (error) {
            console.log('ERROR', error);
            const errorMSG = error.message; //+ ' Please install Metamask';
            alert(errorMSG);
            activate(injected);
          }
        });
      } catch (error) {
        console.log('ERROR', error);
      }
    },
  };
  const Gallery = async () => {
    let gallArr = [];
    const contract = getContract(
      Finalcontractaddress,
      ThetaPunkABI,
      library,
      account
    );
    const txaCount = await contract.balanceOf(account);
    const txa = txaCount.toString();
    setUserTotalNFTS(txa);

    try {
      for (let i = 0; i < txa; i++) {
        const id = await contract.tokenOfOwnerByIndex(account, i);
        const filter = await contract.tokenURI(id.toString());
        const res = await axios.get(filter);

        if (res.status === 200) {
          // console.log('filter', filter);

          // console.log('res', res);
          setNftList((prev) => [
            ...prev,
            { id: id.toString(), nfdData: res.data },
          ]);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  // console.log('nftList', nftList);
  React.useEffect(() => {
    if (active === true && account && chainId === 361) {
      Gallery();
      getMoreData();
    }
  }, [account, active, chainId]);

  React.useEffect(() => {
    getMoreData();
  }, [account]);

  const getMoreData = async () => {
    try {
      // const contract = getContract(
      //   Finalcontractaddress,
      //   ThetaPunkABI,
      //   library,
      //   account
      // );

      const RPC_URL = 'https://eth-rpc-api.thetatoken.org/rpc';
      const httpProvider = new Web3.providers.HttpProvider(RPC_URL);
      const web3 = new Web3(httpProvider);
      const contract = new web3.eth.Contract(
        ThetaPunkABI,
        Finalcontractaddress
      );
      const totalSupp = await contract.methods.totalSupply().call();
      setTotalSupply(parseFloat(totalSupp.toString()));
      const MAX_PUNKS = await contract.methods.MAX_PUNKS_SUPPLY().call();
      setMaxSupply(parseFloat(MAX_PUNKS.toString()));
    } catch (error) {
      console.log('ERROR', error);
    }
  };

  // React.useEffect(() => {
  //   if (JSON.parse(localStorage.getItem('gallArr')) && account) {
  //     setTimeout(() => {
  //       setNftList(JSON.parse(localStorage.getItem('gallArr')));
  //     }, 100);
  //   }
  // }, [account]);

  useEffect(() => {
    const userAddress = window.sessionStorage.getItem('userAddress');
    if (userAddress) {
      data.connectWallet();
    }
  }, []); //eslint-disable-line

  useEffect(() => {
    data.updateUser(account);
  }, [account]); //eslint-disable-line

  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  );
}
