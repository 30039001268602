import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home")),
  },

  {
    exact: true,
    path: "/nft-breed",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Breed/NftBreed")),
  },
  {
    exact: true,
    path: "/mint",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Mint/Mint")),
  },
  {
    exact: true,
    path: "/thetapunks",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Thetapunks/Thetapunks")),
  },
  {
    exact: true,
    path: "/bachlorsclub",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Bachelors/Bachelors")),
  },
  {
    exact: true,
    path: "/roadmap",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Roadmap/Roadmap")),
  },
  {
    exact: true,
    path: "/attributes",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Attributes/Attributes")),
  },
  // {
  //   exact: true,
  //   path: "/attributes",
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/views/pages/Attributes/Attributes")),
  // },
  {
    exact: true,
    path: "/disclaimer",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Desclaimer")),
  },
  {
    exact: true,
    path: "/new-breed",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Breed/NewBreed")),
  },
  {
    exact: true,
    path: "/about-us",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/About/AboutUs")),
  },
  {
    exact: true,
    path: "/wallet",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Wallet/Wallet")),
  },
  {
    exact: true,
    path: "/wallet-2",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Wallet/Wallet_2")),
  },
  {
    exact: true,
    path: "/wallet-history",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Wallet/WalletHistory")),
  },
  {
    exact: true,
    path: "/terms-conditions",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/TermsConditions")),
  },
  {
    exact: true,
    path: "/grant-terms",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/GrantTerms")),
  },
  {
    exact: true,
    path: "/marketplace",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/MarketPlace")),
  },
  {
    exact: true,
    path: "/marketdetail",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/MarketDetail")),
  },
  {
    exact: true,
    path: "/faq",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/FAQ")),
  },
  // {
  //   exact: true,
  //   path: "/admin",
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/views/pages/Admin")),
  // },

  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
