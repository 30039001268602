import { Box, List, ListItem } from "@material-ui/core";
import Telegram from "@material-ui/icons/Telegram";
import Twitter from "@material-ui/icons/Twitter";
import React from "react";
import { FaDiscord } from "react-icons/fa";
import { Link } from "react-router-dom";

const SocialIcon = (props) => {
  return (
    // <img
    //   src="/images/logo.png"
    //   alt="Logo"
    //   {...props}
    //   style={{ marginTop: '-18px' }}
    // />
    <Box className="FooterMenu" style={{ margin: "0" }}>
      <List>
        <ListItem>
          <a href="https://www.twitter.com/punksontheta" color="inherit">
            <Twitter style={{ color: "white" }} />
          </a>
        </ListItem>
        <ListItem>
          <a href="https://discord.com/invite/f48H5Za7YH" color="inherit">
            <FaDiscord style={{ color: "white" }} />
          </a>
        </ListItem>
        <ListItem>
          <a href="https://t.me/thetapunks" color="inherit">
            <Telegram style={{ color: "white" }} />
          </a>
        </ListItem>
      </List>
    </Box>
  );
};

export default SocialIcon;
